<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.tutorials')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'tutorials-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
          </v-row>

          <v-form class="multi-col-validation">
            <v-row class="pb-5">
              <v-col
                cols="12"
                md="12"
              >
                <p>
                  <b>{{ $t('lbl.title') }}</b>: {{ item.name }}
                  <fragment v-if="item.description">
                    <span v-html="item.description"></span>
                  </fragment>
                </p>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <Media
                  :kind="'video'"
                  :controls="true"
                  :muted="false"
                  :autoplay="false"
                  :src="[url_video]"
                  :style="{ width: '750px' }"
                >
                </Media>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  Bold,
  Italic,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak /* Paragraph, Strike, Heading, Underline, Code, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History, */,
} from 'tiptap-vuetify'
import Media from '@dongido/vue-viaudio'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
} from '@mdi/js'

export default {
  components: {
    AppCardCode,
    Media,
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_TUTORIAL,
      isLoading: true,
      editGeoTag: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
      },
      extensions: [BulletList, OrderedList, ListItem, Italic, Bold, HardBreak],
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      item: {},
      showMap: true,
      loading: false,

      url_video: null,
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('tutorials-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      oficinaRenta: state => state.app.oficinaRenta,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  created() {
    if (sessionStorage.getItem('tutorials-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }
    this.getItem()
  },
  methods: {
    ...mapMutations(['setOnlyShow']),
    getItem() {
      if (sessionStorage.getItem('tutorials-id') !== null) {
        const id = sessionStorage.getItem('tutorials-id')
        this.axios
          .get(`tutorials/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.item = res.data.data.data
            if (this.item.is_extern) {
              this.url_video = this.item.url_extern
            } else {
              this.url_video = this.nameB2B === 'wwwww' ? this.rutaS3 + this.item.url : `${this.rutaPublic}storage/${this.item.url}`
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isLoading = false))
      } else {
        this.isLoading = false
      }
    },
  },
}
</script>
